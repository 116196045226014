import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SideNav from "../components/sidenav"

export default function Article({ data }) {
  const post = data.markdownRemark
  const sideNavFlag = data.allMarkdownRemark.edges.length !== 1
  const toc = (
    post.tableOfContents
    ? (<div>
        <p className="text-xl sm:text-2xl">目次</p>
        <div className="toc" dangerouslySetInnerHTML={{ __html: post.tableOfContents }} />
        <hr />
      </div>)
    : null
  )

  const ogImagePath = data.allFile.edges.find(file => {
    return file.node.relativePath === post.frontmatter.ogp
  })


  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={ogImagePath ? ogImagePath.node.publicURL : post.frontmatter.ogp}
        pathname={post.fields.slug}
      />
      <div className="flex flex-col md:flex-row">
        {sideNavFlag && (<SideNav data={data.allMarkdownRemark.edges} post={post} position="left" />)}
        <main
          className={
            `min-h-screen w-full pt-4 pb-8 md:static md:max-h-full md:overflow-visible
              ${sideNavFlag && (`sm:pt-4 md:pt-32 md:w-2/3 lg:w-3/4 xl:w-4/5`)}
              ${!sideNavFlag && (`sm:pt-32`)}
            `
          }
        >

          <div className="markdown">
            <h1 className="text-2xl sm:text-3xl mb-8">{post.frontmatter.title}</h1>
            { toc }
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            <p className="text-right text-xs">
              公開投稿日：{post.frontmatter.date}
              &emsp;最終更新日：{post.frontmatter.last_modified}
            </p>
          </div>
        </main>
        {sideNavFlag && (<SideNav data={data.allMarkdownRemark.edges} post={post} position="right" />)}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $dir: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      tableOfContents(maxDepth: 3)
      frontmatter {
        title
        description
        ogp
        date(formatString: "YYYY/MM/DD")
        last_modified(formatString: "YYYY/MM/DD")
      }
      fields {
        slug
      }
    }
    allMarkdownRemark(
      filter: {
        fields: {dir: {eq: $dir}},
        frontmatter: {status: {eq: "publish"}}
      },
      sort: {fields: fields___filename}
    ) {
      edges {
        node {
          id
          fields {
            dir
            filename
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    allFile(filter: {ext: {regex: "/gif|jpg|png|svg/"}}) {
      edges {
        node {
          id
          relativePath
          publicURL
        }
      }
    }
  }
`